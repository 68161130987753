

const Server = new function() {
  this.getDevices = async function() {
    // return [{"id":1,"name":"E-Lumen","registerTime":20210809165137,"UIDefinition":[{"type":"Variable","parameters":["Moisture","0%"]},{"type":"Variable","parameters":["Humidity","1%"]},{"type":"Variable","parameters":["Temperature","[ROWS_GETPROP0]*"]},{"type":"Variable","parameters":["Light Intensity","25 Lux"]},{"type":"LineGraph","parameters":["Index (n)","Moisture (%)","[[1629890570,15],[1629890577,95],[1629890585,90],[1629892417,100],[1629895464,70],[1629895525,75]]","[[1629890570,20],[1629890577,50],[1629890585,40],[1629892417,100],[1629895464,50],[1629895525,55]]","[[1629890570,25],[1629890577,30],[1629890585,50],[1629892417,100],[1629895464,30],[1629895525,35]]"]},{"type":"LineGraph2","parameters":["Index (n)","Moisture (%)","[[0,15],[1,95],[2,90],[3,100],[4,70],[5,75]]"]}],"onSameNetwork":false,"status":false}];
    return await this.fetchData('database/getDeviceList.php');
  }

  this.getFilledInDevice = async function(_id) {
    // return {"error":false,"result":{"id":1,"name":"E-Lumen","registerTime":20210809165137,"UIDefinition":[{"type":"Variable","parameters":["Moisture","0%"]},{"type":"Variable","parameters":["Humidity","1%"]}, {"type": "WhiteSpace"},{"type":"Variable","parameters":["Temperature","[ROWS_GETPROP0]*"]},{"type":"Variable","parameters":["Light Intensity","25 Lux"]},{"type":"LineGraph","parameters":["Index (n)","Moisture (%)","[[1629890570,15],[1629890577,95],[1629890585,90],[1629892417,100],[1629895464,70],[1629895525,75]]","[[1629890570,20],[1629890577,50],[1629890585,40],[1629892417,100],[1629895464,50],[1629895525,55]]","[[1629890570,25],[1629890577,30],[1629890585,50],[1629892417,100],[1629895464,30],[1629895525,35]]"]},{"type":"LineGraph","parameters":["Index (n)","Moisture (%)","[[0,15],[1,95],[2,90],[3,100],[4,70],[5,75]]"]}],"onSameNetwork":false,"status":false}};
    return await this.fetchData('database/getFilledInDevice.php', "id=" + _id);
  }

  this.getUnboundDevices = async function() {
    // return new Promise(function (resolve) {
    //   setTimeout(function() {
    //     let arr = [
    //       {"id":1,"name":"E-Lumen","registerTime":20210809165137,"UIDefinition":[{"type":"Variable","parameters":["Moisture","0%"]},{"type":"Variable","parameters":["Humidity","1%"]},{"type":"Variable","parameters":["Temperature","[ROWS_GETPROP0]*"]},{"type":"Variable","parameters":["Light Intensity","25 Lux"]},{"type":"LineGraph","parameters":["Index (n)","Moisture (%)","[[1629890570,15],[1629890577,95],[1629890585,90],[1629892417,100],[1629895464,70],[1629895525,75]]","[[1629890570,20],[1629890577,50],[1629890585,40],[1629892417,100],[1629895464,50],[1629895525,55]]","[[1629890570,25],[1629890577,30],[1629890585,50],[1629892417,100],[1629895464,30],[1629895525,35]]"]},{"type":"LineGraph2","parameters":["Index (n)","Moisture (%)","[[0,15],[1,95],[2,90],[3,100],[4,70],[5,75]]"]}],"onSameNetwork":false,"status":false},
    //       {"id":2,"name":"E-Lumen2", "registerTime":20210809165137,"UIDefinition":[{"type":"Variable","parameters":["Moisture","0%"]},{"type":"Variable","parameters":["Humidity","1%"]},{"type":"Variable","parameters":["Temperature","[ROWS_GETPROP0]*"]},{"type":"Variable","parameters":["Light Intensity","25 Lux"]},{"type":"LineGraph","parameters":["Index (n)","Moisture (%)","[[1629890570,15],[1629890577,95],[1629890585,90],[1629892417,100],[1629895464,70],[1629895525,75]]","[[1629890570,20],[1629890577,50],[1629890585,40],[1629892417,100],[1629895464,50],[1629895525,55]]","[[1629890570,25],[1629890577,30],[1629890585,50],[1629892417,100],[1629895464,30],[1629895525,35]]"]},{"type":"LineGraph2","parameters":["Index (n)","Moisture (%)","[[0,15],[1,95],[2,90],[3,100],[4,70],[5,75]]"]}],"onSameNetwork":true,"status":false}
    //     ];
    //     if (Math.random() < .9) arr = [];

    //     resolve(arr);
    //   }, 500);
    // })
    
    return await this.fetchData('database/getUnboundDevices.php');
  }

  this.bindDevice = async function(_id) {
    return await this.fetchData('database/bindDevice.php', "id=" + _id);
  }

  this.fetchData = async function(_url, _parameters = "", _attempts = 0) {
    let parameters = _parameters;
    let response = await new Promise(function (resolve) {
      fetch(_url, {
        method: 'POST', 
        body: parameters,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include'
      }).then(function (_result) {
        resolve(_result);
      }, function (_error) {
        resolve("E_noConnection");
      });
    });
    
    if (response.status != 200 && response != "E_noConnection") 
    {
      if (_attempts >= 5) return {error: "E_responseError", result: false};
      return await new Promise(function (resolve) {
        setTimeout(async function () {
          resolve(await Server.fetchData(_url, _parameters, _attempts + 1));
        }, 500);
      }) 
    }
    
    if (response == "E_noConnection") return {error: "E_noConnection", result: false};
    
    let result = await response.text();
    try {
      result = JSON.parse(result);
    } catch (e) {}

    // if (result.error == "E_noAuth") App.promptAuthentication();
    return result;
  }
}
window.Server = Server;

export default Server;
